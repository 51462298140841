import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import Header from 'src/components/main-layout/components/header';
import Sidebar from 'src/components/main-layout/components/sidebar';
import classes from 'src/components/main-layout/main-layout.module.sass';
import { getUserProjects } from 'src/store/actions/auth';
import { Breadcrumbs } from '../breadcrumbs';
import SidebarItem from './components/sidebar/components/SidebarItem';
import { useLanguage } from 'src/hooks';
import { Security } from '@mui/icons-material';
import { routes } from 'src/utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { flexibleCompare } from '@fullcalendar/core/internal';
import { useSelector } from 'react-redux';

export function MainLayout({ breadcrumbs = [], renderSidebarContent, children }) {
  const { localizeText } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const sideMenuAccesses = useSelector((state) => state.app.currentProject?.accesses?.sideMenu) ?? {};

  const renderMenu = !window.location.href.endsWith('/projects');

  const isCart = window.location.href.includes('/order/');
  useEffect(() => {
    getUserProjects();
  }, []);

  return (
    <Stack className={classes.root}>
      <Header />
      <Stack direction="row" overflow="hidden" height="100%" display="flex" justifyContent="center">
        {/* Sidebar */}
        {renderMenu && (
          <Box className={classes.sidebar}>
            {renderSidebarContent ? renderSidebarContent() : <Sidebar />}
            {sideMenuAccesses.support && (
              <Stack gap="8px" marginTop="8px">
                <SidebarItem
                  tab={{
                    key: 'faq',
                    title: localizeText.SUPPORT_BUTTON,
                    icon: <Security sx={{ height: 24, width: 24 }} />,
                    path: routes.faqs.path,
                  }}
                  isActive={location.pathname === routes.faqs.path}
                  onClick={() => {
                    navigate(routes.faqs.path);
                  }}
                />
              </Stack>
            )}
          </Box>
        )}
        {/* Content */}
        <Box className={`${classes.content} ${isCart ? classes.contentCart : ''}`}>
          <Stack gap="12px">
            <Breadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
            {children}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
