import { ButtonBase, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  flexBasis: 0,
  flexGrow: 1,
  borderRadius: '8px',
  backgroundColor: theme.palette.customColors.buttonLight,
}));

const PaymentButton = ({ icon, label, onClick, selected }) => {
  return (
    <StyledButton
      onClick={onClick}
      sx={{ border: '1px solid', borderColor: selected ? 'primary.main' : 'transparent' }}
    >
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center" gap={2}>
        {icon}
        <Typography fontSize={12} color="primary.main">
          {label}
        </Typography>
      </Stack>
    </StyledButton>
  );
};

export default PaymentButton;
