import { ChevronLeft, ShoppingBasket } from '@mui/icons-material';
import { Box, Button, Card, Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { routes } from 'src/utils/routes';
import AmountToggle from '../../components/amount-toggle';
import { AddShoppingIcon } from '../../icons';
import { addToOrder, countOrder, getProjectPackages, joinProject, removeFromOrder } from '../../store/actions/app';
import { EVENTS, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './buy-ticket.module.sass';

const BuyTicketPage = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const transText = TEXT[lang];
  const order = useSelector((state) => state.app.order);
  const user = useSelector((state) => state.auth.user);

  const { projectUuid: id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  const isJoinedProject = user?.projectUuids?.includes(id);

  const handleAddToOrder = (p) => {
    document.getElementById('shopping-cart')?.classList?.add('shake');
    addToOrder(id, p, () => {
      navigate(routes.orderDetail.path.replace(':projectUuid', id));
    });
    setTimeout(() => {
      document.getElementById('shopping-cart')?.classList?.remove('shake');
    }, 5000);
  };

  const handleClickAddPackage = (p) => {
    if (!isJoinedProject) {
      eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
        title: TEXT[lang].CONFIRM,
        content: TEXT[lang].PARTICIPATE_PROJECT,
        onOk: () => {
          joinProject(id);
          handleAddToOrder(p);
        },
      });
    } else {
      handleAddToOrder(p);
    }
  };

  const handleRemoveFromOrder = (p) => {
    document.getElementById('shopping-cart')?.classList?.add('shake');
    removeFromOrder(id, p);
    setTimeout(() => {
      document.getElementById('shopping-cart')?.classList?.remove('shake');
    }, 5000);
  };

  const handleCount = (p, count) => {
    document.getElementById('shopping-cart')?.classList?.add('shake');
    countOrder(id, p, count);
    setTimeout(() => {
      document.getElementById('shopping-cart')?.classList?.remove('shake');
    }, 5000);
  };

  const goToBasket = () => {
    navigate(routes.orderDetail.path.replace(':projectUuid', id));
  };

  useEffect(() => {
    const fetchData = async () => {
      const packages = await getProjectPackages(
        id,
        queryString.stringify({
          countryId: 250,
          since: 1,
          till: 1726428683462,
          limit: 10,
          offset: 0,
        })
      );
      setPackages(packages);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  const getAmount = (_package) => {
    return (
      order.find((item) => item.projectUuid === id)?.packages.find((_p) => _p.package.id === _package.id)?.count || 0
    );
  };

  const handleShowDetail = (p) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid: id,
      packages: [p],
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <Box
          container
          sx={{
            display: 'flex',
            width: '100%',
            marginTop: '-2rem',
            boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
            backgroundColor: 'white',
            padding: '20px',
          }}
        >
          <h2 className={classes.title}>{transText.PURCHASE_SERVICE_PACKAGE}</h2>
        </Box>
      </div>
      {isLoading ? (
        <Loader />
      ) : packages.length > 0 ? (
        <Stack direction="row" flexWrap="wrap" justifyContent="center" className={classes.optionList} gap={2}>
          {packages.map((p, i) => {
            const name = p?.localization?.[lang]?.name?.trim();
            const amount = getAmount(p);
            const price = p.price?.['643']?.price ?? 0;
            return (
              <Card sx={{ borderRadius: '12px', width: { xs: '100%', sm: '320px' } }}>
                <div className={classes.option}>
                  <div className={classes.title}>
                    «{name && !name.includes('null') ? name : p.localization?.[LOCALIZATION.ru_RU]?.name.trim()}»
                  </div>
                  <Grid container spacing={2}>
                    {p.services.map((service, _i) => {
                      const title = service.localization?.[lang]?.title;
                      return (
                        <Grid key={_i} item xs={12} md={6}>
                          <span className={classes.service}>
                            {title && !title.includes('null')
                              ? title
                              : service.localization?.[LOCALIZATION.ru_RU]?.title}
                          </span>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleShowDetail(p)}
                    sx={{
                      borderRadius: '8px',
                      fontWeight: 600,
                      color: 'primary.main',
                      backgroundColor: 'customColors.buttonLight',
                    }}
                  >
                    {transText.SHOW_PACKAGE_DETAILS}
                  </Button>
                  {!amount ? (
                    <Button
                      variant="contained"
                      startIcon={<ShoppingBasket />}
                      className={classes.button}
                      onClick={() => handleClickAddPackage(p)}
                    >
                      <NumberFormat
                        value={price}
                        suffix=" ₽"
                        decimalScale={2}
                        displayType="text"
                        thousandSeparator=" "
                        decimalSeparator=","
                      />
                    </Button>
                  ) : (
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Button
                        variant="contained"
                        onClick={goToBasket}
                        className={classes.button}
                        sx={{ py: 0, backgroundColor: 'success.main', borderRadius: '12px' }}
                      >
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 600,
                              color: 'success.contrastText',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {transText.IN_CART}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 600,
                              color: 'success.contrastText',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <NumberFormat
                              value={price * amount}
                              suffix=" ₽"
                              decimalScale={2}
                              displayType="text"
                              thousandSeparator=" "
                              decimalSeparator=","
                            />
                          </Typography>
                        </Stack>
                      </Button>
                      <Stack>
                        <AmountToggle
                          amount={amount}
                          onAdd={() => handleClickAddPackage(p)}
                          onDistract={() => handleRemoveFromOrder(p)}
                          handleCount={(count) => handleCount(p, count)}
                        />
                      </Stack>
                    </Stack>
                  )}
                </div>
              </Card>
            );
          })}
        </Stack>
      ) : (
        <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
          {MESSAGES[lang].NO_DATA}
        </Typography>
      )}
    </div>
  );
};

export default BuyTicketPage;
