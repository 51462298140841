import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { getLocalizationValue } from 'src/utils/common';
import { routes } from 'src/utils/routes';
import { ChangePasswordForm, ConfirmModal, MainLayout, PackageDetailModal, ViewImageModal } from '../../components';
import AmountToggle from '../../components/amount-toggle';
import CheckOutForm from '../../components/check-out-form';
import { CompanyFormModal } from '../../components/company-form-modal';
import { addToOrder, countOrder, removeFromOrder } from '../../store/actions/app';
import { EVENTS, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './order.module.sass';

const OrderPage = () => {
  const navigate = useNavigate();
  const { projectUuid: id } = useParams();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const projects = useSelector((state) => state.auth.projects);
  const isLoadingProjects = useSelector((state) => state.auth.isLoadingProjects);
  const order = useSelector((state) => {
    const _order = (state.app.order || []).filter((item) =>
      state.auth.projects?.find((p) => p.uuid === item.projectUuid)
    );
    return id ? _order.filter((i) => i.projectUuid === id) : _order;
  }, shallowEqual);

  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);

  console.log('Cart', cart);
  console.log('Order', order);
  const currentProject = projects.find((p) => p.uuid === id);

  const currentProjectName = useMemo(() => {
    return getLocalizationValue(currentProject?.name, lang, 'fullName') || currentProject?.name;
  }, [currentProject, lang]);

  const breadcrumbs = id
    ? [
        { path: '/', title: TEXT[lang].MAIN_PAGE },
        {
          path: routes.projectDetail.path.replace(':projectUuid', id),
          title: currentProjectName,
        },
        { path: null, title: TEXT[lang].BASKET },
      ]
    : [
        { path: '/', title: TEXT[lang].MAIN_PAGE },
        { path: null, title: TEXT[lang].BASKET },
      ];

  useEffect(() => {
    setCart([]);
  }, []);

  const handleSelect = async (e, projectUuid, pack) => {
    setIsLoading(true);
    const isChecked = e.target.checked;
    if (!isChecked) {
      setSelectedAll(false);
    }
    if (isChecked) {
      const packages = order.find((item) => item.projectUuid === projectUuid)?.packages || [];
      setCart(cart.concat(packages.find((p) => p.package.id === pack.id)));
    } else {
      setCart(cart.filter((p) => p.package.id !== pack.id));
    }
    setIsLoading(false);
  };
  const handleSelectAll = (e) => {
    setIsLoading(true);
    const isChecked = e.target.checked;
    setSelectedAll(isChecked);

    if (!isChecked) {
      setSelectedAll(false);
    }
    if (isChecked) {
      const packages = order.find((item) => item.projectUuid === id)?.packages || [];
      setCart(packages);
    } else {
      setCart(cart.filter((item) => item.projectUuid === id));
    }
    setIsLoading(false);
  };

  const handleAddToOrder = (projectUuid, p) => {
    addToOrder(projectUuid, p);
  };

  const handleRemoveFromOrder = (projectUuid, p) => {
    removeFromOrder(projectUuid, p);
  };

  const handleCount = (projectUuid, p, count) => {
    countOrder(projectUuid, p, count);
  };

  const handleShowDetail = (p) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid: id,
      packages: [p.package],
    });
  };

  const handleClearCart = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        const orders = order.filter((item) => item.projectUuid === id);
        orders.forEach((item) => {
          item.packages.forEach((p) => {
            countOrder(item.projectUuid, p.package, 0);
          });
        });
      },
    });
  };

  const isEmptyProjectCart = useMemo(() => {
    return order.length === 0 || order.every((item) => item.projectUuid !== id || item.packages.length === 0);
  }, [order]);

  useEffect(() => {
    if (order?.length) {
      let allPackages = [];
      order.forEach((item) => (allPackages = allPackages.concat(item.packages)));
      setCart(allPackages);
      setSelectedAll(true);
    }
  }, [order]);

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <Box className={classes.root}>
        <Stack direction="row" alignItems="center" gap="8px" mt={4} mb={{ xs: 0, sm: 4 }}>
          <IconButton onClick={() => navigate(routes.projectDashboard.path.replace(':projectUuid', id))}>
            <ChevronLeft />
          </IconButton>
          <h1 className={classes.title}>{TEXT[lang].BASKET}</h1>
        </Stack>
        {isLoadingProjects ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={order.length ? 8 : 12}>
              <Stack gap={8}>
                <div className={classes.content}>
                  {!isEmptyProjectCart ? (
                    <>
                      <Card sx={{ borderRadius: '12px', padding: '5px 10px', mb: 2 }}>
                        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                          <FormControlLabel
                            onChange={handleSelectAll}
                            control={<Checkbox sx={{ padding: '10px' }} />}
                            checked={selectedAll}
                            label={<Typography fontWeight={600}>{TEXT[lang].SELECT_ALL}</Typography>}
                          />
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleClearCart}
                            sx={{
                              borderRadius: '8px',
                              fontWeight: 600,
                              color: 'primary.main',
                              backgroundColor: 'customColors.buttonLight',
                            }}
                          >
                            {TEXT[lang].CLEAR_CART}
                          </Button>
                        </Stack>
                      </Card>
                      <Card sx={{ borderRadius: '12px', padding: '10px' }}>
                        {order.map((item, index) => (
                          <Stack gap={8} key={index}>
                            {item.packages.map((p, i) => {
                              const _package = p.package;
                              const projectUuid = item.projectUuid;
                              const project = projects.find((project) => project.uuid === projectUuid);
                              const name = _package.localization?.[lang]?.name;
                              const projectLogo = project?.photos?.logo?.[0];
                              return (
                                project && (
                                  <Stack key={_package.id + i} direction="row" className={classes.project} gap="10px">
                                    <Stack justifyContent="center">
                                      <Checkbox
                                        onChange={(e) => handleSelect(e, projectUuid, _package)}
                                        checked={cart.some((c) => _package.id === c.package.id)}
                                        sx={{ p: 0 }}
                                      />
                                    </Stack>
                                    <Stack justifyContent="center">
                                      <img
                                        className={classes.image}
                                        width="120"
                                        height="120"
                                        src={projectLogo}
                                        alt="logo"
                                      />
                                    </Stack>
                                    <Stack justifyContent="space-between" flexBasis={0} flexGrow={1} gap={4}>
                                      <div className={classes.name}>
                                        {name && !name.includes('null')
                                          ? name
                                          : _package.localization?.[LOCALIZATION.ru_RU]?.name || _package.name}
                                      </div>
                                      <Typography fontSize={14}>{currentProjectName}</Typography>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => handleShowDetail(p)}
                                        sx={{
                                          borderRadius: '8px',
                                          fontWeight: 600,
                                          color: 'primary.main',
                                          backgroundColor: 'customColors.buttonLight',
                                          width: '80%',
                                        }}
                                      >
                                        {TEXT[lang].SHOW_PACKAGE_DETAILS}
                                      </Button>
                                    </Stack>
                                    <Stack justifyContent="space-between" alignItems="center" gap={4}>
                                      <Typography color="primary.main" fontSize={{ xs: 16, sm: 18 }} fontWeight={600}>
                                        <NumberFormat
                                          value={_package.price?.['643']?.price * p.count}
                                          suffix=" ₽"
                                          decimalScale={2}
                                          displayType="text"
                                          thousandSeparator=" "
                                          decimalSeparator=","
                                        />
                                      </Typography>
                                      <AmountToggle
                                        amount={p.count}
                                        onAdd={() => handleAddToOrder(projectUuid, _package)}
                                        onDistract={() => handleRemoveFromOrder(projectUuid, _package)}
                                        handleCount={(count) => handleCount(projectUuid, _package, count)}
                                        style={{ margin: 0 }}
                                      />
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => handleCount(projectUuid, _package, 0)}
                                        sx={{
                                          borderRadius: '8px',
                                          fontWeight: 600,
                                          color: 'primary.main',
                                          backgroundColor: 'customColors.buttonLight',
                                          width: '100%',
                                        }}
                                      >
                                        {MESSAGES[lang].DELETE}
                                      </Button>
                                    </Stack>
                                  </Stack>
                                )
                              );
                            })}
                          </Stack>
                        ))}
                      </Card>
                    </>
                  ) : (
                    <p style={{ fontSize: 20, textAlign: 'center' }}>{TEXT[lang].EMPTY_BASKET}</p>
                  )}
                </div>
              </Stack>
            </Grid>
            {!isEmptyProjectCart && cart.length > 0 && (
              <Grid item xs={12} sm={12} lg={4}>
                <CheckOutForm isLoading={isLoading} cart={cart} projectUuid={id} />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <ConfirmModal />
      <ViewImageModal />
      <ChangePasswordForm />
      <PackageDetailModal />
      <CompanyFormModal />
    </MainLayout>
  );
};

export default OrderPage;
