import { ArrowBack, ArrowForward, Clear, Close, Delete, Download, ModeEdit, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainUserProfile } from 'src/components';
import { AnketaForm } from 'src/components/anketa';
import { AvatarUser } from 'src/components/avatar-user';
import { MUITable } from 'src/components/mui-table';
import UserRole from 'src/components/user-role';
import UserStatus from 'src/components/user-status';
import { useDownloadFile, useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';
import { getMyTicket, updateMember } from 'src/store/actions/app';
import { getFullName, isSafari } from 'src/utils/common';
import { DEFAULT_DATE_FORMAT, EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';
import classes from './accreadition.module.sass';
import AddNewMember from './AddNewMember';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateMemberModal from './components/CreateMemberModal';

function MembersTable({
  members,
  total,
  isLoadingMembers,
  rolesById,
  projectUuid,
  refetchMembersOfProject,
  autoFillTemplate,
  userInvitations,
  fetchUserInvitations,
}) {
  const { localizeText, localizeMessage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { downloadFile } = useDownloadFile();

  const user = useSelector((state) => state.auth.user);

  const [searchText, setSearchText] = useState('');
  const [indexSelectedMember, setIndexSelectedMember] = useState(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const [selectedMember, setSelectedMember] = useState(members[indexSelectedMember]);
  const [createdUser, setCreatedUser] = useState(null);

  const downloadAccesses = useSelector((state) => state.app.currentProject?.accesses?.downloads) ?? {};

  const queryParams = qs.parse(location.search);

  const getMemberInfo = (selectedMember) => {
    const selectedMemberProjectSettings = selectedMember?.projectSettings?.[projectUuid];
    const selectedMemberRole = rolesById[selectedMemberProjectSettings?.mainRoleId];
    const isLocked = selectedMember?.projectSettings?.[projectUuid]?.isLocked;
    const isAccredited = selectedMember?.projectSettings?.[projectUuid]?.isAccredited;
    const readyToAccreditate = selectedMember?.projectSettings?.[projectUuid]?.readyToAccreditate;

    return {
      member: selectedMember,
      role: selectedMemberRole,
      isLocked,
      isAccredited,
      isAccreditedBy: selectedMember?.accreditedBy !== '',
      readyToAccreditate,
    };
  };

  const selectedMemberProjectSettings = selectedMember?.projectSettings?.[projectUuid];
  const selectedMemberRole = rolesById[selectedMemberProjectSettings?.mainRoleId];
  const isLocked = selectedMember?.projectSettings?.[projectUuid]?.isLocked;
  const { isAccreditedBy: isAccreditedByMember } = getMemberInfo(selectedMember);

  const currentUserProjectSettings = user.projectSettings?.[projectUuid];
  const currentUserQuota = currentUserProjectSettings?.quotaByRole?.[currentUserProjectSettings?.mainRoleId] || 0;
  const isAccreditedBy = user.accreditedBy !== '';
  const hasInvitations = Object.keys(userInvitations).length > 0;
  const ableAddMember = hasInvitations;

  const headCells = [
    {
      id: 'info',
      numeric: false,
      disablePadding: true,
      label: `${localizeText.FULLNAME} / ${localizeText.EMAIL}`,
      renderRow: (row) => {
        return (
          <Stack direction="row" gap="4px" alignItems="center">
            <Box
              sx={{
                '& *': {
                  height: '36px !important',
                  width: '36px !important',
                  fontSize: '1rem !important',
                },
              }}
            >
              <AvatarUser user={row} hideUploadAvatar={true} />
            </Box>
            <Stack>
              <Typography variant="body1" fontWeight={600}>
                {getFullName(row)}
              </Typography>
              <Typography variant="body2">{row.email || ''}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: localizeText.DATE,
      renderRow: (row) => (
        <Typography variant="body2">
          {row.createdAt ? moment(row.createdAt).format(DEFAULT_DATE_FORMAT) : ''}
        </Typography>
      ),
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: localizeText.ROLE,
      renderRow: (row) => {
        const { role } = getMemberInfo(row);
        return <UserRole role={role} icon={<CheckCircleIcon />} />;
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: localizeText.STATUS,
      renderRow: (row) => {
        const { isAccredited, readyToAccreditate } = getMemberInfo(row);
        return (
          <UserStatus
            isAccredited={isAccredited}
            readyToAccreditate={readyToAccreditate}
            project={projectUuid}
            icon={<CheckCircleIcon />}
          />
        );
      },
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: '',
      renderRow: (row) => {
        const { isAccredited, isAccreditedBy } = getMemberInfo(row);
        const userUuid = row.uuid;

        const handleGotoMyTicket = async (e) => {
          e.stopPropagation();
          e.preventDefault();
          try {
            const response = await getMyTicket(userUuid, projectUuid, true);
            downloadFile(response, 'Билет');
          } catch (error) {
            createNotification(localizeMessage.ERROR, 'error');
          }
          // const url = routes.myTicket.path.replace(':userUuid', userUuid).replace(':projectUuid', projectUuid);
          // if (isSafari) {
          //   navigate(url);
          // } else {
          //   window.open(url, '_blank');
          // }
        };

        return (
          <Stack direction="row" gap="8px">
            {isAccredited && downloadAccesses.downloadMemberTicket && (
              <IconButton sx={{ border: '1px solid', borderRadius: '4px' }} size="small" onClick={handleGotoMyTicket}>
                <Download />
              </IconButton>
            )}
            {isAccreditedBy && (
              <IconButton
                color="error"
                size="small"
                sx={{ borderRadius: '4px', border: '1px solid' }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRemoveFromProject(row?.uuid);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleClickRow = (member) => {
    setIndexSelectedMember(members.findIndex((m) => m.uuid === member.uuid));
  };

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(location.pathname + '?' + query);
    },
    [queryParams]
  );

  const handleSearch = ({ query }) => {
    if (query && query.trim()) {
      setQueryParams({ ...queryParams, query: query, page: 1 });
    } else {
      let _queryParams = { ...queryParams, page: 1 };
      delete _queryParams.query;
      setQueryParams(_queryParams);
    }
  };

  const handleForceEdit = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: localizeText.CONFIRM,
      content: `${localizeMessage.FORCE_EDIT_CONFIRM}`,
      onOk: async () => {
        await updateMember(selectedMember.uuid, {
          updatingUserFields: { forceEdit: true, currentProjectUuid: projectUuid },
        });
        await refetchMembersOfProject();
      },
    });
  };

  const handleRemove = async (memberUuid) => {
    try {
      await userAPIs.removeFromProject(memberUuid, projectUuid);
      createNotification(localizeMessage.DELETE_SUCCESSFULLY, 'success');
      refetchMembersOfProject();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message?.error || localizeMessage.ERROR);
    }
  };

  const handleRemoveFromProject = async (memberUuid, ignoreConfirm) => {
    if (ignoreConfirm) {
      await handleRemove(memberUuid);
      return;
    }
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: localizeText.CONFIRM,
      content: `${localizeMessage.ALERT_REMOVE_USER_FROM_PROJECT}`,
      onOk: async () => {
        await handleRemove(memberUuid);
      },
    });
  };

  const closeUserModal = () => {
    setIndexSelectedMember(-1);
    setCreatedUser(null);
  };

  useEffect(() => {
    setSelectedMember(members[indexSelectedMember] || createdUser);
  }, [members, indexSelectedMember, createdUser]);

  return (
    <Stack marginY="16px" gap="16px">
      <Stack direction="row" gap="8px 12px" flexWrap={{ xs: 'nowrap', md: 'wrap' }} justifyContent="flex-end">
        <TextField
          name="query"
          size="small"
          placeholder={localizeText.SEARCH}
          sx={{
            width: { xs: '100%', md: 'fit-content' },
            minWidth: { xs: 'auto', md: '300px' },
            fontSize: '1rem !important',
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={(e) => {
            console.log(e.key);
            if (e.key === 'Enter') {
              handleSearch({ query: e.target.value });
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: searchText ? 'visible' : 'hidden' }}
                onClick={() => {
                  setSearchText('');
                  handleSearch({ query: '' });
                }}
              >
                <Clear />
              </IconButton>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          sx={{ borderRadius: '4px', boxShadow: 'none' }}
          variant="contained"
          onClick={() => {
            handleSearch({ query: searchText });
          }}
        >
          {localizeText.SEARCH}
        </Button>
      </Stack>
      <MUITable
        id="project-members-table"
        loading={isLoadingMembers}
        headCells={headCells}
        rows={members}
        rowsNum={(searchText ? members.length : total) ?? 0}
        onClickRow={handleClickRow}
      />

      {selectedMember && (
        <Dialog
          maxWidth="lg"
          open={true}
          onClose={closeUserModal}
          PaperProps={{
            style: {
              width: '100%',
            },
          }}
        >
          <DialogTitle>
            {localizeText.ANKETA}
            <IconButton sx={{ position: 'absolute', top: '1rem', right: '1rem' }} onClick={closeUserModal}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Stack sx={{ minWidth: '300px', minHeight: '350px' }}>
              {showAddNew ? (
                <AddNewMember
                  projectUuid={projectUuid}
                  rolesById={rolesById}
                  setShowAddNew={setShowAddNew}
                  userInvitations={userInvitations}
                  fetchUserInvitations={fetchUserInvitations}
                  refetchMembersOfProject={refetchMembersOfProject}
                />
              ) : (
                <>
                  <Stack
                    alignItems="center"
                    sx={{
                      width: '100%',
                      margin: 'auto',
                      minWidth: '300px',
                    }}
                  >
                    <MainUserProfile user={selectedMember} role={selectedMemberRole} projectUuid={projectUuid} />
                  </Stack>

                  {/* <Stack sx={{ position: 'relative' }}>
                    <Stack direction="row" gap="8px" sx={{ position: 'absolute', top: '10px', right: '0' }}>
                      {// {isLocked && (
                       // <IconButton color="primary" className={classes.editBtn} onClick={handleForceEdit}>
                         // <ModeEdit />
                        //</IconButton>
                      //)} 
                      }
                      {isAccreditedByMember && (
                        <IconButton
                          color="error"
                          className={classes.editBtn}
                          onClick={() => handleRemoveFromProject(selectedMember?.uuid)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack> */}

                  {!isLoadingMembers && (
                    <AnketaForm
                      user={selectedMember}
                      isLocked={isLocked}
                      projectUuid={projectUuid}
                      ableAddMember={ableAddMember}
                      quota={currentUserQuota}
                      isAccreditedBy={isAccreditedBy}
                      hasInvitations={hasInvitations}
                      onAddNewAccreadition={() => setShowAddNew(true)}
                      onReloadMembers={refetchMembersOfProject}
                      autoFillTemplate={autoFillTemplate}
                    />
                  )}
                </>
              )}
            </Stack>
          </DialogContent>
          {indexSelectedMember != -1 && (
            <DialogActions sx={{ padding: '0.5rem !important' }}>
              <Stack justifyContent="space-between" direction="row" width="100%">
                <IconButton
                  disabled={indexSelectedMember === 0}
                  onClick={() => {
                    setIndexSelectedMember(Math.max(0, indexSelectedMember - 1));
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  disabled={indexSelectedMember === members.length - 1}
                  onClick={() => setIndexSelectedMember(Math.min(members.length, indexSelectedMember + 1))}
                >
                  <ArrowForward />
                </IconButton>
              </Stack>
            </DialogActions>
          )}
        </Dialog>
      )}

      <CreateMemberModal
        projectUuid={projectUuid}
        rolesById={rolesById}
        userInvitations={userInvitations}
        fetchUserInvitations={fetchUserInvitations}
        refetchMembersOfProject={refetchMembersOfProject}
        setCreatedUser={setCreatedUser}
      />
    </Stack>
  );
}

export default MembersTable;
