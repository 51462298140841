import { Button, Card, Chip, Divider, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useLanguage } from 'src/hooks';
import { deleteOrder } from 'src/store/actions/auth';
import { EVENTS, PAYMENT_METHODS, STATUS_PAYMENT } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { viewFile } from 'src/utils/request';
import moment from 'moment';
import { AccountBalance, CallSplit, CreditCard, Delete, SimCardDownload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { useState } from 'react';

function OrderItem({ order, project, isDistributing, productsLeft }) {
  const navigate = useNavigate();

  const { lang, localizeMessage, localizeText } = useLanguage();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownFile = async (docUuid) => {
    try {
      if (isDownloading) return;
      setIsDownloading(true);
      await viewFile(`/users/current/documents/${docUuid}`);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDeleteOrder = (order) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteOrder(order.id);
      },
    });
  };

  const handleShowDetail = (p) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid: project.uuid,
      packages: [p],
    });
  };

  const handleDistributeOrder = () => {
    navigate(routes.projectDistribute.path.replace(':projectUuid', project.uuid), {
      state: { selectedOrder: order.id },
    });
  };

  if (!order || !project) return null;

  const paymentMethod = order.trxId ? PAYMENT_METHODS.bankCard : PAYMENT_METHODS.bankAccount;
  const projectLogo = project.photos?.logo?.[0];

  const isPaid = ['paid', 'confirmed'].includes(order.status);
  const totalAmount = order.products.reduce((acc, el) => acc + el.amount, 0);
  const isDestributed = order.distributed_count === totalAmount;
  const step = isPaid ? (isDestributed ? 3 : 2) : 1;

  return (
    <Card sx={{ flexGrow: 1, borderRadius: 3 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" sx={{ backgroundColor: 'grey.100', p: 4 }}>
        <Stack direction="row" flexGrow={{ xs: 0, md: 1 }} width={{ xs: '100%', md: 'auto' }}>
          <Stack gap={2}>
            <Typography fontSize={18} fontWeight={600}>
              {localizeText.ORDER_FROM} {moment(order.created_at * 1000).format('DD.MM.YYYY')}
            </Typography>
            <Typography fontSize={16} fontWeight={600} color="info.main">
              № {order.order_number}
            </Typography>
          </Stack>
          <Stack
            flexGrow={1}
            sx={{
              '& .MuiStep-root': {
                maxWidth: 200,
              },
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'success.main',
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                color: 'black',
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'warning.main',
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                color: 'black',
              },
            }}
          >
            <Stepper sx={{ justifyContent: 'center' }} activeStep={step} alternativeLabel>
              <Step>
                <StepLabel>{STATUS_PAYMENT.undetermined.label[lang]}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{STATUS_PAYMENT.paid.label[lang]}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{STATUS_PAYMENT.distributed.label[lang]}</StepLabel>
              </Step>
            </Stepper>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          gap={2}
          direction={{ xs: 'row', md: 'column' }}
          justifyContent="space-between"
          width={{ xs: '100%', md: 'auto' }}
        >
          <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
            {paymentMethod.value === PAYMENT_METHODS.bankCard.value ? (
              <CreditCard color="primary" />
            ) : (
              <AccountBalance color="primary" />
            )}
            <Typography fontSize={12} fontWeight={600} color="primary.main">
              {paymentMethod.label[lang]}
            </Typography>
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            <Typography fontSize={18} fontWeight={600}>
              <NumberFormat
                value={order.total_sum ?? 0}
                suffix=" ₽"
                decimalScale={2}
                displayType="text"
                thousandSeparator=" "
                decimalSeparator=","
              />
            </Typography>
            <Chip
              color={isPaid ? 'success' : 'default'}
              label={isPaid ? STATUS_PAYMENT.paid.label[lang] : localizeText.ORDER_NOT_PAID}
              sx={{ minHeight: 20, '& .MuiChip-label': { py: '2px' } }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" sx={{ p: 4 }} gap={4}>
        <Stack flexGrow={1} gap={4}>
          {order.products?.map((product, indexP) => (
            <Stack key={indexP} direction="row" gap={4} alignItems="center">
              <img width="120" height="120" src={projectLogo} style={{ borderRadius: 12 }} />
              <Stack flexGrow={1} gap={2}>
                <Typography fontSize={18} fontWeight={600}>
                  {product.localization?.[lang].name}
                </Typography>
                {/* {product.userId !== order.ownerId && <Chip label={getFullName(memberByUuid[product.userId])} />} */}
                <Typography>{project.localization?.[lang]?.fullName || project.name}</Typography>
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleShowDetail({ id: product.productId, localization: product.localization })}
                    sx={{
                      borderRadius: '8px',
                      fontWeight: 600,
                      color: 'primary.main',
                      backgroundColor: 'customColors.buttonLight',
                      flexGrow: 0.3,
                    }}
                  >
                    {localizeText.SHOW_PACKAGE_DETAILS}
                  </Button>
                  {!isDistributing && (
                    <Chip
                      sx={{ minHeight: 20, '& .MuiChip-label': { py: '2px', whiteSpace: 'nowrap' } }}
                      label={
                        <NumberFormat
                          value={product.price ?? 0}
                          suffix={` ₽ - ${product.amount} ${localizeText.ORDER_UNITS}`}
                          decimalScale={2}
                          displayType="text"
                          thousandSeparator=" "
                          decimalSeparator=","
                        />
                      }
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Divider sx={{ display: { xs: 'none', md: 'block' } }} flexItem orientation="vertical" />
        <Stack width={{ xs: '100%', md: 250 }} gap={4} justifyContent="flex-start" alignSelf="flex-start">
          {isDistributing ? (
            <Stack gap={2}>
              <Button
                startIcon={<CallSplit />}
                size="large"
                variant="contained"
                color="warning"
                sx={{
                  fontWeight: 600,
                  pointerEvents: 'none',
                  borderRadius: '8px',
                  opacity: 0.6,
                }}
              >
                {localizeText.ORDER_PRODUCTS_LEFT.replace('${a}', productsLeft).replace('${b}', totalAmount)}
              </Button>
              <Typography color="grey.500" fontSize={14} textAlign="center">
                {localizeText.DISTRIBUTE_ORDER_TO_USERS}
              </Typography>
            </Stack>
          ) : (
            <>
              {!isPaid ? (
                paymentMethod === PAYMENT_METHODS.bankCard.value ? (
                  <Button
                    startIcon={<CreditCard />}
                    size="large"
                    variant="contained"
                    color="warning"
                    sx={{ borderRadius: '8px' }}
                  >
                    {localizeText.PAY}
                  </Button>
                ) : (
                  <Button
                    startIcon={<SimCardDownload />}
                    disabled={!order.properties?.invoiceDocId}
                    size="large"
                    variant="contained"
                    color="warning"
                    sx={{ borderRadius: '8px' }}
                    onClick={() => handleDownFile(order.properties?.invoiceDocId)}
                  >
                    {localizeText.DOWNLOAD_INVOICE}
                  </Button>
                )
              ) : (
                <Stack gap={2}>
                  <Button
                    startIcon={<CallSplit />}
                    size="large"
                    variant="contained"
                    color={isDestributed ? 'success' : 'warning'}
                    sx={{ borderRadius: '8px' }}
                    onClick={handleDistributeOrder}
                  >
                    <Stack textAlign="center">
                      <Typography
                        fontWeight={600}
                        fontSize={16}
                        color={isDestributed ? 'success.contrastText' : 'warning.contrastText'}
                      >
                        {localizeText.DISTRIBUTE_ORDER_BTN}
                      </Typography>
                      <Typography fontSize={12} color={isDestributed ? 'success.contrastText' : 'warning.contrastText'}>
                        {isDestributed
                          ? localizeText.REDISTRIBUTE_ORDER_BTN
                          : localizeText.ORDER_PRODUCTS_LEFT.replace(
                              '${a}',
                              totalAmount - order.distributed_count
                            ).replace('${b}', totalAmount)}
                      </Typography>
                    </Stack>
                  </Button>
                  <Typography color="grey.500" fontSize={14} textAlign="center">
                    {localizeText.DISTRIBUTE_ORDER_TO_USERS}
                  </Typography>
                </Stack>
              )}
              {order.status === STATUS_PAYMENT.undetermined.value && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: '8px', width: 200, alignSelf: 'center' }}
                  onClick={() => handleDeleteOrder(order)}
                  startIcon={<Delete />}
                >
                  {localizeText.DELETE_ORDER}
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}

export default OrderItem;
