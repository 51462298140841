import { useRef } from 'react';
import classes from './amount-toggle.module.sass';
export default function AmountToggle({ amount, onAdd, onDistract, handleCount, style, min = 0, max = 999 }) {
  const inputRef = useRef();
  const handleAdd = () => {
    if (inputRef.current.value < inputRef.current.max) onAdd();
  };
  const handleDistract = (e) => {
    if (inputRef.current.value > inputRef.current.min) onDistract();
  };

  const handleChange = (e) => {
    let newVal = Number(e.target.value);
    if (newVal > max) newVal = max;
    else if (newVal < min) newVal = min;
    e.target.value = newVal;
    handleCount(newVal);
  };
  return (
    <div className={classes.root} style={style}>
      <div className={classes.minus} onClick={handleDistract}>
        -
      </div>
      <input
        ref={inputRef}
        className={classes.amount}
        onChange={handleChange}
        value={amount}
        min={min}
        max={max}
        type="number"
        onKeyDown={(e) => {
          if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault();
          }
        }}
      />
      <div className={classes.plus} onClick={handleAdd}>
        +
      </div>
    </div>
  );
}
