import { Add, ChevronLeft, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { keyBy, uniqueId } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainUserProfile } from 'src/components';
import AmountToggle from 'src/components/amount-toggle';
import { AvatarUser } from 'src/components/avatar-user';
import Loader from 'src/components/loader';
import MemberProfile from 'src/components/members/MemberProfile';
import OrderItem from 'src/components/order/OrderItem';
import { useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';
import { getMembersByProject } from 'src/store/actions/app';
import { getMyOrder } from 'src/store/actions/auth';
import { getFullName } from 'src/utils/common';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';

function DistributeOrders({ project, projectUuid }) {
  const user = useSelector((state) => state.auth.user);
  const myOrders = useSelector((state) => state.auth.myOrders);
  const allMembers = useSelector((state) => state.app.members);

  const navigate = useNavigate();
  const location = useLocation();
  const { localizeText, localizeMessage, getLocalizationValue } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [distributedProducts, setDistributedProducts] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const members = useMemo(
    () => (allMembers ? allMembers.filter((m) => user.uuid === m.uuid || m.accreditedBy !== '') : []),
    [allMembers, user]
  );

  useEffect(() => {
    if (projectUuid) {
      Promise.all([getMembersByProject({ projectUuid }), getMyOrder(projectUuid)]).finally(() => setIsLoading(false));
    }
  }, [projectUuid]);

  useEffect(() => {
    if (!isLoading && !members?.length) {
      navigate(routes.myOrder.path.replace(':projectUuid', projectUuid));
    }
  }, [members, isLoading]);

  const selectedOrderId = useMemo(() => location.state.selectedOrder, [location]);
  const selectedOrder = useMemo(() => {
    const orderObj = myOrders.find((el) => el.id === selectedOrderId);
    if (!orderObj) return null;

    return orderObj;
  }, [selectedOrderId, myOrders]);

  const handleDistribute = async () => {
    let body = { [selectedOrderId]: dataArr };
    try {
      setIsSubmitting(true);
      await userAPIs.distributeOrders({ projectUuid, data: body });
      createNotification(localizeMessage.SAVE_SUCCESSFULLY, { type: 'success' });
      navigate(routes.myOrder.path.replace(':projectUuid', projectUuid));
    } catch (error) {
      console.error(error);
      createErrorNotification(localizeMessage.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddProductToUser = (memberUuid) => {
    const productId = selectedProducts[memberUuid];
    if (!productId) return;

    setDistributedProducts((prev) => {
      const currentCount = prev[memberUuid]?.[productId] || 0;
      return {
        ...prev,
        [memberUuid]: {
          ...prev[memberUuid],
          [productId]: currentCount + 1,
        },
      };
    });
  };

  const handleDistractProductToUser = (memberUuid) => {
    const productId = selectedProducts[memberUuid];
    if (!productId) return;

    setDistributedProducts((prev) => {
      const currentCount = prev[memberUuid]?.[productId] || 0;
      const newCount = Math.max(0, currentCount - 1);

      return {
        ...prev,
        [memberUuid]: {
          ...prev[memberUuid],
          [productId]: newCount,
        },
      };
    });
  };

  const handleCountProductToUser = (memberUuid, count) => {
    const productId = selectedProducts[memberUuid];
    if (!productId) return;

    setDistributedProducts((prev) => {
      return {
        ...prev,
        [memberUuid]: {
          ...prev[memberUuid],
          [productId]: count,
        },
      };
    });
  };

  const handleProductSelect = (memberUuid, productId) => {
    setDistributedProducts((prev) => ({ ...prev, [memberUuid]: {} }));
    setSelectedProducts((prev) => ({
      ...prev,
      [memberUuid]: productId,
    }));
  };

  const getTotalDistributedForOtherUsers = (productId, userUuid) => {
    return Object.keys(distributedProducts).reduce((total, key) => {
      return userUuid && key === userUuid ? total : total + (distributedProducts[key][productId] || 0);
    }, 0);
  };

  const getMaxProductsForMember = (memberUuid) => {
    const productId = selectedProducts[memberUuid];
    const total = productId ? selectedOrder.products.find((el) => el.productId === productId)?.amount ?? 0 : 0;
    const distributed = getTotalDistributedForOtherUsers(productId, memberUuid);
    return total - distributed;
  };

  const getMaxProducts = (productId) => {
    const total = productId ? selectedOrder.products.find((el) => el.productId === productId)?.amount ?? 0 : 0;
    const distributed = getTotalDistributedForOtherUsers(productId);
    return total - distributed;
  };

  const getAmount = (memberUuid) => {
    const productId = selectedProducts[memberUuid];
    if (!productId) return 0;
    return distributedProducts[memberUuid]?.[productId] || 0;
  };

  const dataArr = useMemo(() => {
    return Object.entries(distributedProducts).flatMap(([userUuid, products]) =>
      Object.entries(products)
        .filter(([_, count]) => !!count)
        .map(([packageUuid, count]) => ({
          userUuid,
          packageUuid,
          count,
        }))
    );
  }, [distributedProducts]);

  const totalLeft = useMemo(() => {
    return (
      selectedOrder.products.reduce((acc, el) => acc + el.amount, 0) -
      selectedOrder.products.reduce((acc, el) => acc + getTotalDistributedForOtherUsers(el.productId), 0)
    );
  }, [selectedOrder, getTotalDistributedForOtherUsers]);

  useEffect(() => {
    setDistributedProducts(
      selectedOrder.products?.reduce((acc, el) => {
        if (el.usersAmount) {
          const newAcc = { ...acc };
          Object.keys(el.usersAmount).forEach((uuid) => {
            if (!el.usersAmount[uuid]) return;
            newAcc[uuid] = { [el.productId]: el.usersAmount[uuid] };
            handleProductSelect(uuid, el.productId);
          });
          return newAcc;
        }
        return acc;
      }, {})
    );
  }, [selectedOrder]);

  return (
    <div>
      <Card sx={{ borderRadius: 0, p: 4, mb: 4 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <IconButton onClick={() => navigate(routes.myOrder.path.replace(':projectUuid', projectUuid))}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4" flexGrow={1} textAlign="center" fontWeight={600}>
            {localizeText.DISTRIBUTE_ORDERS}
          </Typography>
        </Stack>
      </Card>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack>
          <OrderItem isDistributing order={selectedOrder} project={project} productsLeft={totalLeft} />
          <Card sx={{ flexGrow: 1, borderRadius: 3, mt: 4 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ backgroundColor: 'grey.100', p: 4 }}
            >
              <Typography fontSize={18} fontWeight={600} color="primary.main">
                {localizeText.DISTRIBUTION_ORDER_TO_USERS}
              </Typography>
              <Button
                disabled={isSubmitting || !dataArr.length}
                startIcon={isSubmitting && <Loader size="1rem" sx={{ color: '#fff' }} />}
                variant="contained"
                color="success"
                sx={{ borderRadius: '8px' }}
                onClick={handleDistribute}
              >
                {localizeText.SAVE}
              </Button>
            </Stack>
            <Stack sx={{ p: 4 }} gap={4}>
              {members.map((member) => {
                const maxProducts = getMaxProductsForMember(member.uuid);
                return (
                  <Stack key={member.uuid} direction={{ xs: 'column', md: 'row' }} alignItems="center" gap={4}>
                    <Stack direction="row" gap={4} width={{ xs: '100%', md: 'auto' }}>
                      <AvatarUser isSquare user={member} hideUploadAvatar />
                      <Stack width={150} alignSelf="flex-start">
                        <Typography fontWeight={600}>{getFullName(member)}</Typography>
                        <Typography>{member.companyName}</Typography>
                        <Typography>{member.email}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" flexGrow={1} alignItems="center" gap={4} width={{ xs: '100%', md: 'auto' }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel shrink={true} required variant="standard">
                          {localizeText.PRODUCT_SERVICE}
                        </InputLabel>
                        <Select
                          value={selectedProducts[member.uuid] || ''}
                          onChange={(e) => handleProductSelect(member.uuid, e.target.value)}
                          variant="standard"
                          margin="dense"
                          label={localizeText.PRODUCT_SERVICE}
                        >
                          {selectedOrder.products.map((product) => (
                            <MenuItem
                              disabled={!getMaxProducts(product.productId)}
                              key={product.productId}
                              value={product.productId}
                            >
                              {getLocalizationValue(product, 'name')}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <AmountToggle
                        min={0}
                        max={maxProducts}
                        amount={getAmount(member.uuid)}
                        onAdd={() => handleAddProductToUser(member.uuid)}
                        onDistract={() => handleDistractProductToUser(member.uuid)}
                        handleCount={(count) => handleCountProductToUser(member.uuid, count)}
                      />
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Card>
        </Stack>
      )}
    </div>
  );
}

export default DistributeOrders;
