import {
  Box,
  Button,
  ButtonBase,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { v4 as uuidv4 } from 'uuid';
import { setPayingOrders } from '../../store/actions/app';
import { createMyOrder } from '../../store/actions/auth';
import { getTransMessages } from '../../utils/common';
import { EVENTS, LOCALIZATION, PAYMENT_METHODS, TEXT, WEBSITE_BASE_URL } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import Loader from '../loader';
import classes from './check-out-form.module.sass';
import { useQuery } from 'react-query';
import { userAPIs } from 'src/services';
import { AccountBalance, CreditCard } from '@mui/icons-material';
import PaymentButton from './payment-button';

function CheckOutForm({ isLoading, cart, projectUuid }) {
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.bankCard.value);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const payingOrders = useSelector((state) => state.app.payingOrders);
  const company = useSelector((state) => state.auth.company);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const fetchOffer = async ({ queryKey }) => {
    var langCode = 'en';
    if (lang === LOCALIZATION.ru_RU) {
      langCode = 'ru';
    }

    const [, projectUuid] = queryKey;
    const response = await userAPIs.getOfferFile({
      projectUuid,
      lang: langCode,
    });
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);
    return imageUrl;
  };

  const { isOffersLoading, data: offer } = useQuery(['fetchOffer', projectUuid], fetchOffer, {
    enabled: !!projectUuid,
    staleTime: 0,
  });

  console.log('oferrs', offer);
  const handleCreateOrder = async () => {
    const transMessages = getTransMessages();
    var langCode = 'EN';
    if (lang === LOCALIZATION.ru_RU) {
      langCode = 'RU';
    }
    try {
      const uuidOrder = uuidv4();
      const body = {
        orderType: paymentMethod,
        projectUuid,
        langCode: langCode,
        currency: '643',
        backUrlSucess: `${WEBSITE_BASE_URL}/payment/success?order=${uuidOrder}&projectUuid=${projectUuid}`,
        backUrlFail: `${WEBSITE_BASE_URL}/payment/fail?projectUuid=${projectUuid}`,
        order: [
          {
            basket: cart.map((item) => ({ amount: item.count, type: 'package', id: item.package.id })),
          },
        ],
      };
      setIsSubmitting(true);
      let response;
      switch (paymentMethod) {
        case PAYMENT_METHODS.bankCard.value:
          response = await createMyOrder(body);
          break;
        default:
          response = await createMyOrder(body);
          break;
      }
      if (response) {
        switch (paymentMethod) {
          case PAYMENT_METHODS.bankCard.value:
            createNotification(transMessages.PAYMENT_PROCESS_SUCCESSFUL);
            break;
          default:
            createNotification(transMessages.PAYMENT_INVOICE_PROCESS_SUCCESSFUL);
            break;
        }

        setPayingOrders((payingOrders || []).concat([{ uuid: uuidOrder, projectUuid, cart }]));
        if (response.url) {
          window.open(response.url, '_self');
        } else {
          navigate(routes.paymentSuccess.path + `?order=${uuidOrder}&projectUuid=${projectUuid}`);
        }
      } else {
        createErrorNotification(transMessages.PAYMENT_PROCESS_FAIL);
      }
    } catch (error) {
      createErrorNotification(transMessages.PAYMENT_PROCESS_FAIL);
    } finally {
      setIsSubmitting(false);
    }
  };

  const total = cart.reduce((p, c) => (p += c.package.price['643']?.price * c.count || 0), 0);
  const cartSize = cart.reduce((p, c) => (p += c.count), 0);
  return (
    <Stack justifyContent="center" alignItems="center">
      {isLoading || isOffersLoading ? (
        <Loader />
      ) : (
        <Stack sx={{ width: '100%', gap: 2 }}>
          <Card sx={{ borderRadius: '12px', padding: '16px' }}>
            <Typography fontWeight={600} textAlign="center" variant="h6">
              {TEXT[lang].CHOOSE_PAYMENT_METHOD}:
            </Typography>
            <Stack mt={2} direction="row" sx={{ height: '120px', width: '100%' }} gap={2}>
              <PaymentButton
                selected={paymentMethod === PAYMENT_METHODS.bankCard.value}
                onClick={() => setPaymentMethod(PAYMENT_METHODS.bankCard.value)}
                label={PAYMENT_METHODS.bankCard.label[lang]}
                icon={<CreditCard color="primary" />}
              />
              <PaymentButton
                selected={paymentMethod === PAYMENT_METHODS.bankAccount.value}
                onClick={() => setPaymentMethod(PAYMENT_METHODS.bankAccount.value)}
                label={PAYMENT_METHODS.bankAccount.label[lang]}
                icon={<AccountBalance color="primary" />}
              />
            </Stack>
          </Card>
          {!company && paymentMethod === PAYMENT_METHODS.bankAccount.value && (
            <Card sx={{ borderRadius: '12px', padding: '16px' }}>
              <Typography lineHeight={1.2} fontWeight={600} textAlign="center" variant="h6">
                {TEXT[lang].CREATE_ORGANIZATION_TITLE}
              </Typography>
              <Button
                color="warning"
                fullWidth
                variant="contained"
                sx={{ borderRadius: '8px', mt: 2, fontWeight: 600 }}
                onClick={() => eventBus.emit(EVENTS.OPEN_COMPANY_MODAL)}
              >
                {TEXT[lang].ADD_COMPANY}
              </Button>
            </Card>
          )}
          {cartSize > 0 && (!!company || paymentMethod !== PAYMENT_METHODS.bankAccount.value) && (
            <Card sx={{ borderRadius: '12px', padding: '16px' }}>
              <Typography lineHeight={1.2} fontWeight={600} textAlign="center" variant="h6">
                {TEXT[lang].YOUR_ORDER}:
              </Typography>
              <Stack mt={8} sx={{ width: '100%' }} gap={2}>
                {cart.map((p) => {
                  const name = p.package.localization?.[lang]?.name;
                  return (
                    <Stack
                      key={p.id}
                      direction="row"
                      justifyContent="space-between"
                      width="100%"
                      sx={{ fontSize: '17px', lineHeight: '28px' }}
                      gap={2}
                    >
                      <div>
                        {name && !name.includes('null') ? name : p.package.localization?.[LOCALIZATION.ru_RU]?.name}
                        {p.count > 1 && ` (${p.count})`}
                      </div>
                      <Typography
                        color="primary.main"
                        fontSize={{ xs: 16, sm: 18 }}
                        whiteSpace="nowrap"
                        fontWeight={600}
                      >
                        <NumberFormat
                          value={p.package.price['643']?.price * p.count}
                          suffix=" ₽"
                          decimalScale={2}
                          displayType="text"
                          thousandSeparator=" "
                          decimalSeparator=","
                        />
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack mt={8} sx={{ width: '100%' }} gap={2}>
                <Typography textAlign="center">
                  {TEXT[lang].QUANTITY}: {cartSize} {TEXT[lang].ORDER_UNITS}.
                </Typography>
                <Typography lineHeight={1.2} fontWeight={600} textAlign="center" variant="h6">
                  {TEXT[lang].TOTAL}:{' '}
                  <NumberFormat
                    value={total}
                    suffix=" ₽"
                    decimalScale={2}
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                  />
                </Typography>
              </Stack>
              <Divider sx={{ pt: 4 }} />
              <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
                <Checkbox
                  onChange={() => setAgreeTerms(!agreeTerms)}
                  checked={agreeTerms}
                  sx={{ height: 'fit-content', fontSize: 17 }}
                />
                <Link
                  download={TEXT[lang].OFFER + '.pdf'}
                  href={offer}
                  fontWeight={600}
                  sx={{ fontSize: 14, color: 'primary.main' }}
                >
                  {TEXT[lang].AGREE_WITH_TERMS} {TEXT[lang].TERMS_OF_USE_OF_THE_TRADING_PLATFORM}
                </Link>
              </Stack>
              <Button
                color="success"
                fullWidth
                variant="contained"
                sx={{ borderRadius: '8px', mt: 2, fontWeight: 600 }}
                startIcon={
                  isSubmitting && (
                    <CircularProgress
                      sx={{ width: '25px !important', height: '25px !important', color: '#00000042' }}
                    />
                  )
                }
                disabled={cart.length === 0 || isSubmitting || !agreeTerms}
                onClick={handleCreateOrder}
              >
                {paymentMethod !== PAYMENT_METHODS.bankAccount.value ? TEXT[lang].PAY : TEXT[lang].PAYMENT_INVOICE}
              </Button>
            </Card>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default CheckOutForm;
